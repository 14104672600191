* {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p {
  border: medium none;
  margin: 0;
  padding: 0;
}
a,
input,
select,
textarea {
  margin: 0;
  outline: medium none;
  padding: 0;
}
html {
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "CircularStdBold";
}
img,
fieldset {
  border: 0 none;
}
a {
  border: medium none;
  outline: medium none;
}
input[type="search"] {
}

img {
  border: medium none;
  height: auto;
  max-width: 100%;
  outline: medium none;
  vertical-align: middle;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  margin: 0;
  padding: 0;
}
div,
h1,
h2,
h3,
h4,
span,
p,
input,
form,
img,
hr,
img,
a {
  border: medium none;
  margin: 0;
  padding: 0;
}
a:hover {
  text-decoration: none;
}
.clear {
  clear: both;
}
ul::after {
  clear: both;
  content: "";
  display: block;
}
ul {
  list-style: outside none none;
}
@font-face {
  font-family: "CircularStdBook";
  src: url("../fonts/CircularStdBook.eot");
  src: url("../fonts/CircularStdBook.eot") format("embedded-opentype"),
    url("../fonts/CircularStdBook.woff2") format("woff2"),
    url("../fonts/CircularStdBook.woff") format("woff"),
    url("../fonts/CircularStdBook.ttf") format("truetype"),
    url("../fonts/CircularStdBook.svg#CircularStdBook") format("svg");
}
@font-face {
  font-family: "CircularStdBold";
  src: url("../fonts/CircularStdBold.eot");
  src: url("../fonts/CircularStdBold.eot") format("embedded-opentype"),
    url("../fonts/CircularStdBold.woff2") format("woff2"),
    url("../fonts/CircularStdBold.woff") format("woff"),
    url("../fonts/CircularStdBold.ttf") format("truetype"),
    url("../fonts/CircularStdBold.svg#CircularStdBold") format("svg");
}
body {
  margin: 0;
  padding: 0;
  font-family: "CircularStdBook";
  background: #292054;
}
.container {
  max-width: 1350px;
}
.header {
  background: #292054;
  height: 110px;
  position: static;
  top: -100px;
  transition: 0.6s all;
  padding-top: 15px;
}
.header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: 0.6s all;
  background: #ef3cff;
}
.header.fixed .logo:after {
  border-top: 60px solid #ef3cff;
  transition: 0.6s all;
}
.header.fixed .navigation ul li a:hover {
  color: #000;
}
.logo {
  position: absolute;
  left: 50%;
  top: 0px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  margin: 0;
  padding: 0;
  width: 320px;
  z-index: 1;
}
.logo img {
  position: relative;
  z-index: 1;
  width: 150px;
}
.logo:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 60px solid #292054;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  height: 0;
  width: auto;
  content: "";
  margin: 0 auto;
  transition: 0.6s all;
}
h1.mobile-menu {
  display: none;
}
a.closebtn {
  display: none;
}
.navigation {
  padding: 0px 0 0px 0;
}
.navigation ul {
  list-style: none;
  text-align: right;
  float: left;
  padding: 30px 0 0 0;
  width: 38%;
}
.navigation ul.right {
  float: right;
  text-align: left;
}

.navigation ul li {
  display: inline-block;
  margin-right: 60px;
  position: relative;
}
.navigation ul li:last-child {
  margin-right: 0;
}
.navigation ul li a {
  color: #fff;
  font-size: 16px;
  font-family: "CircularStdBold";
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
}
.navigation ul li a:hover {
  color: #cc3cff;
}

.banner {
  position: relative;
}
.banner img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.banner-txt-main {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.banner-txt-main-b {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table;
}
.banner-txt-main-c {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: table-cell;
}

.utc-sec {
  margin: -200px 0 0 0;
  padding: 0;
}
.utc-bg-main {
  background: #644dd3;
  border-radius: 20px;
  padding: 50px;
}
.utc-info {
  text-align: center;
  margin: 0;
  padding: 0;
}
.utc-info h1 {
  color: #fff;
  font-size: 36px;
  font-family: "CircularStdBold";
  letter-spacing: 1px;
  margin-bottom: 15px;
  padding: 0;
  text-align: center;
}
.utc-info span {
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 25px;
  padding: 0;
  display: block;
  text-align: center;
}
button.utc-mint-btn {
  color: #fff;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: "CircularStdBold";
  text-transform: uppercase;
  background: #ef3cff;
  border-radius: 30px;
  display: inline-block;
  padding: 13px 0;
  width: 350px;
  transition: 0.4s all;
  cursor: pointer;
}
button.utc-mint-btn:hover {
  background: #322570;
  transition: 0.4s all;
}
button:focus {
  outline: none;
}
.social-nft {
  margin: 30px 0;
  padding: 0;
}
.social-nft ul {
  list-style: none;
  text-align: center;
}
.social-nft ul li {
  display: inline-block;
  margin: 0 6px;
  vertical-align: middle;
  position: relative;
}
.social-nft ul li a {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 100%;
  text-align: left;
  text-decoration: none;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  transition: 0.4s all;
}
.social-nft ul li a:hover {
  transition: 0.4s all;
  background: #322570;
}
.social-nft ul li a:hover img {
  filter: brightness(0) invert(1);
  transition: 0.4s all;
}
.utc-info h6 {
  color: #fff;
  font-size: 24px;
  font-family: "CircularStdBold";
  letter-spacing: 1px;
  margin-bottom: 25px;
  padding: 0;
  line-height: 32px;
  text-align: center;
}
.utc-detail {
  margin: 0;
  padding: 0;
}
.utc-detail ul {
  list-style: none;
  text-align: center;
}
.utc-detail ul li {
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 0;
  padding-left: 25px;
  display: inline-block;
  text-align: left;
  position: relative;
  margin: 0 60px;
}
.utc-detail ul li:before {
  position: absolute;
  top: 3px;
  left: 0;
  font-family: "FontAwesome";
  content: " ";
  width: 18px;
  height: 18px;
  background: url(../images/check-circle-solid.svg);
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 405px;
  overflow: hidden;
}

.item {
  position: absolute;
  opacity: 0;
  transition-duration: 1s ease;
  transform: translateX(-1000px);
}

.active {
  display: block;
  opacity: 1;
  transition-duration: 1s;
  transform: translateX(0px);
}
.back-btn {
  height: 100px;
  width: 80px;
  position: absolute;
  top: 168px;
  z-index: 9;
  color: white;
  background: none;
}
.back-btn:hover {
  cursor: pointer;
}
.next-btn:hover {
  cursor: pointer;
}
.next-btn {
  height: 100px;
  width: 80px;
  position: absolute;
  top: 168px;
  right: 20px;
  z-index: 9;
  color: white;
  background: none;
}

.ethside-mafia-sec {
  padding: 80px 0;
  margin: 0;
}
.ethside-mafia-img {
  width: 100%;
}
.ethside-mafia-img img {
  width: 100%;
  height: 645px;
  border-radius: 100%;
  border: 5px solid #fff;
}
.ethside-mafia-info {
  display: flex;
  align-content: center;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  padding: 0 20px;
}
.ethside-mafia-info h1 {
  color: #fff;
  font-size: 34px;
  font-family: "CircularStdBold";
  letter-spacing: 1px;
  margin-bottom: 25px;
  padding: 0;
  text-align: left;
  width: 100%;
}
.ethside-mafia-info p {
  color: #fff;
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  padding: 0;
  display: block;
  text-align: left;
  width: 100%;
}
.ethside-mafia-info p:last-child {
  margin-bottom: 0;
}

.ethside-game-sec {
  padding: 80px 0 280px;
  margin: 0;
  background: #322570;
}
.title h1 {
  color: #fff;
  font-size: 34px;
  font-family: "CircularStdBold";
  letter-spacing: 1px;
  margin-bottom: 25px;
  padding: 0;
  text-align: center;
}
.title p {
  color: #fff;
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  padding: 0;
  display: block;
  text-align: center;
}
.title p:last-child {
  margin-bottom: 0;
}

.eth-sec {
  margin: -200px 0 0;
}
.ethside-game-slider {
  border: 5px solid #fff;
  border-radius: 10px;
}
.ethside-g-img {
  border-radius: 10px;
}
.ethside-g-img img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
#slide-ethside .owl-prev {
  left: 20px;
  position: absolute;
  top: 44%;
  width: 40px;
  height: 55px;
  color: transparent !important;
  background: transparent !important;
  padding: 0px 0px !important;
  border-radius: 0 !important;
  margin: 0px !important;
  text-align: center;
  border: none;
  opacity: 1;
  transition: 0.4s all;
  box-shadow: none;
}
#slide-ethside .owl-prev::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0;
  box-shadow: none;
  content: "\f104";
  font-size: 70px;
  width: 40px;
  height: 55px;
  line-height: 55px;
  color: #fff;
  transition: 0.4s all;
  font-family: "FontAwesome";
}
#slide-ethside .owl-next {
  right: 20px;
  position: absolute;
  top: 44%;
  width: 40px;
  height: 55px;
  color: transparent !important;
  background: transparent !important;
  padding: 0px 0px !important;
  border-radius: 0 !important;
  margin: 0px !important;
  border: none;
  text-align: center;
  opacity: 1;
  transition: 0.4s all;
  box-shadow: none;
}
#slide-ethside .owl-next::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0;
  box-shadow: none;
  content: "\f105";
  font-size: 70px;
  width: 40px;
  height: 55px;
  line-height: 55px;
  color: #fff;
  transition: 0.4s all;
  font-family: "FontAwesome";
}

.road-map-sec {
  padding: 80px 0;
}
.tp-circle {
  position: absolute;
  top: 0;
  left: 2px;
  right: 0;
  width: 15px;
  height: 15px;
  background: #ef3cff;
  border-radius: 100%;
  margin: 0 auto;
}
.btm-circle {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 2px;
  right: 0;
  width: 15px;
  height: 15px;
  background: #ef3cff;
  border-radius: 100%;
  margin: 0 auto;
}

#cd-timeline {
  position: relative;
  padding: 15px 0;
  margin-top: 50px;
  margin-bottom: 0;
}
#cd-timeline::before {
  content: "";
  position: absolute;
  left: 50%;
  height: 98%;
  width: 2px;
  background: transparent;
  border: 1px dashed #fff;
  margin-left: 0;
}

.cd-timeline-block {
  position: relative;
  margin: 40px 0;
}
.cd-timeline-block::after {
  clear: both;
  content: "";
  display: table;
}
.cd-timeline-block:first-child {
  margin-top: 50px;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}

.cd-timeline-block:last-child {
  margin-bottom: 0;
}

.cd-timeline-img img {
  display: block;
  width: auto;
  height: auto;
  position: relative;
  object-fit: cover;
}

.cd-timeline-img {
  position: absolute;
  top: 0;
  width: 70px;
  height: 70px;
  left: 50%;
  margin-left: -35px;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ef3cff;
  border-radius: 100%;
  box-shadow: none;
}
.cd-timeline-img.is-hidden {
  visibility: hidden;
}
.cd-timeline-img.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-1 0.6s;
  -moz-animation: cd-bounce-1 0.6s;
  animation: cd-bounce-1 0.6s;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.cd-timeline-content::after {
  clear: both;
  content: "";
  display: table;
}

.cd-timeline-content {
  width: 43%;
  position: relative;
  margin: 0;
  padding: 21px 0;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}
.cd-timeline-block:nth-child(odd) .cd-timeline-content {
  float: right;
  text-align: left;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content {
  float: left;
  text-align: right;
}
.cd-timeline-content.is-hidden {
  visibility: hidden;
}
.cd-timeline-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  -moz-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}

.cd-timeline-block:nth-child(odd) .cd-timeline-content.bounce-in {
  -webkit-animation: cd-bounce-2-inverse 0.6s;
  -moz-animation: cd-bounce-2-inverse 0.6s;
  animation: cd-bounce-2-inverse 0.6s;
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.cd-timeline-content p {
  font-size: 20px;
  color: #fff;
  line-height: 28px;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
}
.cd-timeline-content p:last-child {
  margin-bottom: 0;
}

.future-sec {
  padding: 80px 0;
  margin: 0;
  background: #322570;
}
.future-img {
  background: #000;
  width: 100%;
  height: 600px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 5px solid #fff;
}
.future-img img {
  width: 478px;
  height: auto;
}

.footer {
  background: #644dd3;
  padding: 50px 0;
  margin: 0;
}

.footer .social-nft {
  margin-top: 0;
}
ul.f-menu {
  list-style: none;
  margin-bottom: 30px;
  text-align: center;
}
ul.f-menu li {
  padding: 0 0;
  display: inline-block;
  color: #fff;
}
ul.f-menu li a {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.5px;
  padding: 0 24px;
  text-decoration: none;
  text-align: center;
  line-height: 26px;
  transition: 0.4s all;
}
ul.f-menu li a:hover {
  text-decoration: underline;
}

.footer p {
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
  padding: 0;
  text-align: center;
  margin-top: 30px;
}
