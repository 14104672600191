@media screen and (max-width:1280px)
{
.container
{
	max-width:1200px;
}
.navigation ul li
{
    margin-right: 50px;
}
.ethside-mafia-img img
{
    height: 570px;
}
.future-img 
{
    height: 560px;
}
}
@media screen and (max-width:1199px)
{
.header
{
    height: 90px;
}
.container
{
	max-width:96%;
}
.logo img 
{
    width: 130px;
}
.logo
{
    width: 270px;
}
.navigation ul
{
    padding: 20px 0 0 0;
    width: 40%;
}
.navigation ul li 
{
	margin-right:30px;
}
.navigation ul li a
{
    font-size: 15px;
}
.banner img
{
    height: 600px;
}
.utc-info h6 br
{
    display: none;
}
.utc-detail ul li
{
    margin: 0 20px;
}
.ethside-mafia-img img
{
    height: 460px;
}
.future-img 
{
    height: 461px;
}
.future-img img
{
    width: 330px;
    height: auto;
}
}
@media screen and (max-width:991px)
{
.navigation.opacity{
  opacity:1 !important ;
}
.navigation{
	position: fixed;
	right: 0;
	background:#292054;
	top: 0px;
	padding-left: 0;
	padding-right: 0;
	width: 100%;
	box-sizing: border-box;
	opacity: 0;
	pointer-events: none;
	height: 100%;
	margin-top:0;
	padding-top:70px;
	z-index:999;
}
.navigation.menu_show{
	opacity:1;
	pointer-events:inherit;
}
.mobile-menu 
{
    float: left;
    background: transparent;
    padding: 0px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    height: 44px;
    width: 44px;
    border: solid 2px #fff;
    align-content: center;
    cursor: pointer;
    position: relative;
    z-index: 9999;
    border-radius: 3px;
    padding: 5px;
    margin: 0;
}
.bar1, 
.bar2, 
.bar3{
	height: 3px;
	background-color: #fff;
	margin: 2px 0;
	transition: 0.4s;
	width:85%;
	display:block;
}
.mobile-menu.change .bar1{
    -webkit-transform: rotate(-45deg) translate(-5px, 4px);
    transform: rotate(-45deg) translate(-5px, 4px);
    background-color: #fff;
}
.mobile-menu.change .bar2{
	opacity: 0;
}
.mobile-menu.change .bar3{
    -webkit-transform: rotate(45deg) translate(-5px, -5px);
    transform: rotate(45deg) translate(-5px, -5px);
    background-color: #fff;
}
.navigation ul 
{
	width:100%;
	float:none;
	padding:0;
	text-align:left;
	clear:both;
	margin-bottom:0;
}
.navigation ul li
{
    float: none;
    padding: 10px 15px;
    text-align: center;
    display: block;
    margin:0px 20px !important;
	clear:both;
}
.logo img
{
	width:120px!important;
}

.social_rg 
{
    top: 0;
    display: block;
}
.social_rg ul li a
{
    width: 44px;
    height: 44px;
}
.social_rg ul li a img
{
    width:30px;
}
.banner img
{
    height: 500px;
}
.utc-info h1
{
    font-size: 30px;
}
.social-nft ul li a 
{
    width:44px;
    height:44px;
}
.social-nft ul li a  img
{
    width:30px;
}
.utc-bg-main 
{
    padding: 40px 30px;
}
.utc-info h6 
{
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 26px;
}
.utc-detail ul li
{
    margin: 0 15px 15px;
}
.ethside-mafia-sec 
{
    padding: 50px 0;
}
.ethside-mafia-img img
{
    height: auto;
}
.ethside-mafia-info h1
{
    font-size: 30px;
    text-align: center;
}
.ethside-mafia-info p
{
	font-size: 18px;
    text-align: center;
}
.title h1
{
    font-size: 30px;
}
.title p
{
    font-size: 18px;
}
.ethside-game-sec
{
    padding: 50px 0 250px;
}
.road-map-sec
{
    padding: 50px 0;
}
.future-sec
{
    padding: 50px 0;
}
.order-last 
{
    order: 0;
}
.future-img 
{
    height: 705px;
}
.ethside-mafia-info
{
    display: block;
    height: auto;
    padding: 0;
    margin-top: 40px;
}
#cd-timeline
{
    overflow: hidden;
}
.future-img img 
{
    width: 540px;
    height: auto;
}
.footer 
{
    padding: 30px 0;
}
ul.f-menu li a
{
    padding: 0 15px;
}
}
@media screen and (max-width:767px)
{
.banner img
{
	height:auto;
}
.utc-sec
{
    margin: 0;
    padding: 50px 0 0;
}
.ethside-game-sec
{
    padding: 50px 0;
}
.cd-timeline-content
{
    width: 40%;
    padding: 5px 0;
}
.eth-sec {
    margin: 0;
    padding: 0 0 50px 0;
    background: #322570;
}
.future-img 
{
    height: 610px;
}
.future-img img
{
    width: 480px;
    height: auto;
}
}
@media screen and (max-width:667px)
{
.cd-timeline-content p 
{
    font-size: 18px;
    line-height: 26px;
}
.title p br 
{
    display:none;
}
}
@media screen and (max-width:480px)
{
.header
{
    height: auto;
    padding: 15px 0;
}
.mobile-menu 
{
    height: 38px;
    width: 38px;
}
.logo 
{
    width: auto;
    top: -6px;
}
.logo img 
{
    width: 58px!important;
}
.logo:after 
{
    display:none;
}
.utc-bg-main 
{
    padding: 30px 15px;
}
.utc-info h1 
{
    font-size: 24px;
}
button.utc-mint-btn
{
    display: block;
    width: 100%;
}
.utc-info h6 
{
    font-size: 16px;
    line-height: 24px;
}
.utc-detail ul li
{
    margin: 0 0 10px 0;
}
.ethside-mafia-info h1 
{
    font-size: 24px;
    text-align: center;
}
.ethside-mafia-info p 
{
    font-size: 16px;
    text-align: center;
}
.title h1 
{
    font-size: 24px;
}
.title p 
{
    font-size: 16px;
}
.cd-timeline-img 
{
    width: 60px;
    height: 60px;
    margin-left: -30px;
}
.cd-timeline-content p {
    font-size: 15px;
    line-height: 22px;
}
#slide-ethside .owl-prev
{
    top: 40%;
    width: 22px;
    height:40px;
}
#slide-ethside .owl-prev::after
{
    font-size: 50px;
    width: 22px;
    height:40px;
    line-height:38px;
}
#slide-ethside .owl-next
{
    top: 40%;
    width: 22px;
    height: 40px;
}
#slide-ethside .owl-next::after 
{
    font-size: 50px;
    width: 22px;
    height:40px;
    line-height:38px;
}
.future-img 
{
    height: 370px;
}
.future-img img 
{
    width: 290px;
    height: auto;
}
ul.f-menu 
{
    margin-bottom: 0;
}
ul.f-menu li
{
    margin: 0 0 6px 0;
}
ul.f-menu li a 
{
    padding: 0 8px;
}
.cd-timeline-content {
    width: 40%;
    padding: 5px 6px;
}
.cd-timeline-content p br{
	display:none;
}
}
@media screen and (max-width:380px)
{
.logo 
{
    top: -5px;
}
.utc-info h1
{
    font-size: 22px;
}
.ethside-mafia-info h1
{
    font-size: 22px;
}
.future-img 
{
    height: 320px;
}
.future-img img 
{
    width: 250px;
}
.title h1 
{
    font-size: 22px;
}
ul.f-menu li a
{
    font-size: 16px;
}
}
